import { Component,Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-signin-signup',
  templateUrl: './signin-signup.component.html',
  styleUrls: ['./signin-signup.component.scss' , './mq-signin-signup.component.scss']
})
export class SigninSignupComponent {
  currentUrl = '';
  constructor(
    private router: Router,
    private route : ActivatedRoute,
    private authService: AuthServiceService,
    private templateService:TemplateService,
    private _dbService : DbService,
    ) {      
    }
  // businessDetails: any = {
  //   businessName: '',
  //   phoneNumber: '',
  //   email: '',
  //   businessType: '',
  //   city: '',
  //   address: '',
  //   checked: true,
  // };
  updatingObject ={
    id:'',
    websiteUrl:''
  };
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  businessForm: any = 'loginDetails';
  edit : any;
  loader : boolean = false;

  countryCodes : any = [];
  selectedCountry : any;

  ngOnInit(){
    // this.route.params.subscribe(
    //   (params) => {
    //     if(params['edit']){
    //       this.edit = params['edit']
    //       //console.log(this.edit)
    //       this.templateService.getBusinessDetailsById(this.encriptionCryptoService.getValue('businessDetails')).subscribe({
    //         next:(res:any) => {
    //           // //console.log(res.data);
    //           this.businessDetails.name = res.data.business.name;
    //           this.businessDetails.category = res.data.business.category;
    //           this.businessDetails.contact.mobile = res.data.business.contact.mobile;
    //           this.businessDetails.contact.email = res.data.business.contact.email;
    //           this.businessDetails.address.city = res.data.business.address.city;
    //           this.businessDetails.address.address = res.data.business.address.address;
    //           this.businessDetails.address.country = res.data.business.address.country;
    //           this.businessDetails.displayId = res.data.business.displayId;
    //           this.updatingObject.id = res.data.business.id;
    //           this.updatingObject.websiteUrl = res.data.business.websiteUrl;
    //           this.businessDetails.logo = res.data.business.logo;
    //         },
    //         error:(err:any) => {
    //           //console.log(err);
    //         }
    //       })
    //     }
    //   }
    // )
    if(this._dbService.getStaffId() != null){
      this.router.navigate([`/master/business/list-industries`]);
    }
    else{}
    this.currentUrl = this.router.url.split('/')[1];
  }

  disableDropdown : boolean = false;

  continue() {
    if(this.businessDetails.name==""||this.businessDetails.contact.mobile==""||this.businessDetails.contact.email==""){
    this.businessForm="businessDetails";
    this.templateService.openSnack("please fill all mandantory feilds","Ok");
    }
    else{
      if(this.emailRegex.test(this.businessDetails.contact.email)){
        this.businessForm = 'addressDetails';
      }
      else{
        this.templateService.openSnack('please provide valid mail','Ok');
      }
    }
  }
  createBusiness() {
    this.templateService.createBusiness(this.businessDetails).subscribe({
      next:(res:any)=> {
        this.templateService.openSnack("Business Created Sucessfully","Close");
        this.router.navigate(['/user/dashboard/list']);
        // this.encriptionCryptoService.storeValue("businessDetails",res.data.id);
      },
      error:(err)=> {
       this.templateService.openSnack("Something went wrong","Close"); 
      },
    }
    )  
  }


  businessDetails={
    displayId: "",
    name: "",
    subDomain: "",
    contact: {
      name: "",
      mobile: "",
      countryCode: "",
      email: ""
    },
    address: {
      address: "",
      city: "",
      country: "91"
    },
    logo: "",
    category: "",
    createdTimeStamp: new Date(),
    status: "ACTIVE",
    checked: true,
    pwd : ""
  }

  backtoCreate(){
    this.businessForm = 'businessDetails';
  }

  updateBusinessDetails(){
    Object.assign(this.businessDetails,this.updatingObject);
    this.templateService.updateBusiness(this.businessDetails).subscribe({
      next:(res:any) => {
        // //console.log(res.data);
        this.templateService.openSnack("Business Created Sucessfully","Close");
        this.router.navigate(['/user/dashboard/list']);
        // this.encriptionCryptoService.storeValue("businessDetails",res.data.id);
      },
      error:(err:any) => {
        // //console.log(err)
        this.templateService.openSnack("Something went wrong","Close"); 
      }
    })
  }

  // closeImage(){
  //   this.dialogRef.close();
  // }
  signUpCheck(){
    this.businessForm="businessDetails";
  }

  loginWithGoogle(){
      // this.authService.GoogleAuth().then(
      //   (value:any)=>{
      //     //console.log(value);
          
      //   }
      // );
  }
  signIn(){
    this.router.navigate(['/admin/website/preview']);
  }



  //signup
  signup: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  signupHide = true;
  get passwordInput() {
    return this.signup.get('password');
  }

  //signin
  signin: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  signinHide = true;
  get signinPasswordInput() {
    return this.signin.get('password');
  }

  redirectToSignup(){
    if(window.localStorage.getItem('bcid') != null){
      this.router.navigate(['/signup']);
    }
    else{
      this.router.navigate(['/business-type'])
    }
  }
  redirectToSignIn(){
    this.router.navigate(['/login'])
  }

  login(){
    let data = {
      userName:(this.businessDetails.contact.email).toLowerCase(),
      pwd:this.businessDetails.pwd
    }
    this.loader = true;
    this.authService.login(data).subscribe(
      (res:any) => {
        console.log(res.data);
        // this._dbService.setBusinessId(res.data.business[0].id);
        this._dbService.setStaffId(res.data.id);
        this._dbService.setStaffName(res.data.name);
        this._dbService.setFname(res.data.name);
        this.router.navigate(['/master/business/list-industries']);
        this.loader = false;
      },
      (err) =>{
        //console.log(err.error.message);
        this.loader = false;
        this.templateService.openSnack(`${err.error.message}`,'Close')
      }
    )
  }
  
  privacyPolicy(){
    this.router.navigate(['/privacy-policy']);
  }
  termsConditions(){
    this.router.navigate(['/terms-conditions'])
  }

  showToolTip : boolean = false;
  top : any = '';
  upperCaseCheck : boolean = false;
  numberCheck : boolean = false;
  specialCharacterCheck : boolean = false;
  minLengthCheck : boolean = false;

  hide(){
    this.showToolTip = false;
  }

  forgotPassword(){
    this.templateService.openDialog(
      ForgotPasswordComponent,
      'auto',
      '540px',
      '80vw'
    )
  }
}
