import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SigninSignupComponent } from './signin-signup/signin-signup/signin-signup.component';
import { AuthGuard } from './services/auth.guard';
import { PreviewComponent } from './preview/preview.component';

const routes: Routes = [
  {
    path:'',
    component:SigninSignupComponent
  },
  {
    path: 'master',
    loadChildren: () =>
      import(
        './master_modules/master-config-mod/master-config-mod.module'
      ).then((module) => module.MasterConfigModModule),
      canActivate:[AuthGuard]
  },
  {
    path:'template/:id',
    component:PreviewComponent
  },
  {
    path:'page/:id',
    component:PreviewComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
