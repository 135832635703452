<section class="main-section">
    <!-- header section  -->
    <div class="header-section">
        <img src="./../../../../../../../../assets/images/delete_feature_icon.svg" alt="delete_icon" />
    </div>
    <div class="header-left-side">
        Delete Template
    </div>
    <div class="header-sub-text">
        Are you sure you want to delete this template? This action cannot be undone.
    </div>

    <div class="button-section">
        <div class="left-side">
            <div class="cancel-button" (click)="closeBtn()">Cancel</div>
        </div>
        <div class="right-side">
            <div class="apply-button" (click)="deleteComponent()">
                <span *ngIf="deleteIconLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                <span *ngIf="!deleteIconLoader">Delete</span>
            </div>
        </div>
    </div>
</section>