import { Component, ComponentFactoryResolver, ComponentRef, Injectable } from '@angular/core';
// import { V2HerobannerFeatureRightsideImageComponent } from '../master_modules/masters/apps/screening_components/herobanner/v2-herobanner-feature-rightside-image/v2-herobanner-feature-rightside-image.component';
// import { V2HerobannerRatingReviewBgimgImageComponent } from '../master_modules/masters/apps/screening_components/herobanner/v2-herobanner-rating-review-bgimg-image/v2-herobanner-rating-review-bgimg-image.component';
// import { V2HerobannerStatImageComponent } from '../master_modules/masters/apps/screening_components/herobanner/v2-herobanner-stat-image/v2-herobanner-stat-image.component';
// import { V2HerobannerStatImageReverseComponent } from '../master_modules/masters/apps/screening_components/herobanner/v2-herobanner-stat-image-reverse/v2-herobanner-stat-image-reverse.component';
// import { V2HerobannerWithReviewComponent } from '../master_modules/masters/apps/screening_components/herobanner/v2-herobanner-with-review/v2-herobanner-with-review.component';
// import { V2SimpleHeroBannerComponent } from '../master_modules/masters/apps/screening_components/herobanner/v2-simple-hero-banner/v2-simple-hero-banner.component';
// import { V2SimpleHerobanerRightsideBackgroundComponent } from '../master_modules/masters/apps/screening_components/herobanner/v2-simple-herobaner-rightside-background/v2-simple-herobaner-rightside-background.component';
// import { V2SimpleHerobannerButtonImageComponent } from '../master_modules/masters/apps/screening_components/herobanner/v2-simple-herobanner-button-image/v2-simple-herobanner-button-image.component';
// import { V2ProcessCountImageComponent } from '../master_modules/masters/apps/screening_components/process-section/v2-process-count-image/v2-process-count-image.component';
// import { V2ProcessCountImageSideComponent } from '../master_modules/masters/apps/screening_components/process-section/v2-process-count-image-side/v2-process-count-image-side.component';
// import { V2ProcessNumberTextComponent } from '../master_modules/masters/apps/screening_components/process-section/v2-process-number-text/v2-process-number-text.component';
// import { V2ProcessSparkImageSideComponent } from '../master_modules/masters/apps/screening_components/process-section/v2-process-spark-image-side/v2-process-spark-image-side.component';
// import { V2HoverHorizontalServiceSectionComponent } from '../master_modules/masters/apps/screening_components/service_sections/v2-hover-horizontal-service-section/v2-hover-horizontal-service-section.component';
// import { V2ServicesectioneditorComponent } from '../master_modules/masters/apps/screening_components/service_sections/v2-servicesectioneditor/v2-servicesectioneditor.component';
// import { V2VerticleServiceSectionComponent } from '../master_modules/masters/apps/screening_components/service_sections/v2-verticle-service-section/v2-verticle-service-section.component';
// import { V2SingleHorizontalTestimonialComponent } from '../master_modules/masters/apps/screening_components/testimonials/v2-single-horizontal-testimonial/v2-single-horizontal-testimonial.component';
// import { V2SinglePageDoubleTestimonialComponent } from '../master_modules/masters/apps/screening_components/testimonials/v2-single-page-double-testimonial/v2-single-page-double-testimonial.component';
// import { V2SinleVerticleTestimonialComponent } from '../master_modules/masters/apps/screening_components/testimonials/v2-sinle-verticle-testimonial/v2-sinle-verticle-testimonial.component';
// import { V2AboutusComponent } from '../master_modules/masters/apps/screening_components/about-us/v2-aboutus/v2-aboutus.component';
// import { V2AboutusWithBackgroundImageComponent } from '../master_modules/masters/apps/screening_components/about-us/v2-aboutus-with-background-image/v2-aboutus-with-background-image.component';
// import { V2HorizontalFaqComponent } from '../master_modules/masters/apps/screening_components/faq/v2-horizontal-faq/v2-horizontal-faq.component';
// import { V2OpenedFaqComponent } from '../master_modules/masters/apps/screening_components/faq/v2-opened-faq/v2-opened-faq.component';
// import { V2SliderFaqComponentsComponent } from '../master_modules/masters/apps/screening_components/faq/v2-slider-faq-components/v2-slider-faq-components.component';
// import { V2SimpleNavbarPagelinksComponent } from '../master_modules/masters/apps/screening_components/navbars/v2-simple-navbar-pagelinks/v2-simple-navbar-pagelinks.component';
// import { V2ContactusTextInputsComponent } from '../master_modules/masters/apps/screening_components/contact-us/v2-contactus-text-inputs/v2-contactus-text-inputs.component';
// import { V2SimpleContactFormComponent } from '../master_modules/masters/apps/screening_components/contact-us/v2-simple-contact-form/v2-simple-contact-form.component';
// import { V2SimpleFooterComponent } from '../master_modules/masters/apps/screening_components/footers/v2-simple-footer/v2-simple-footer.component';
// import { NollieChooseUsComponent } from '../master_modules/masters/apps/screening_components/choose-us/nollie-choose-us/nollie-choose-us.component';
// import { NollieServicesComponent } from '../master_modules/masters/apps/screening_components/service_sections/nollie-services/nollie-services.component';
// import { NollieServiceTechnologyComponent } from '../master_modules/masters/apps/screening_components/service_sections/nollie-service-technology/nollie-service-technology.component';
// import { NollieContactUsComponent } from '../master_modules/masters/apps/screening_components/contact-us/nollie-contact-us/nollie-contact-us.component';
// import { NollieHerobannerComponent } from '../master_modules/masters/apps/screening_components/herobanner/nollie-herobanner/nollie-herobanner.component';
// import { NollieAboutUsComponent } from '../master_modules/masters/apps/screening_components/about-us/nollie-about-us/nollie-about-us.component';
// import { NollieFooterComponent } from '../master_modules/masters/apps/screening_components/footers/nollie-footer/nollie-footer.component';
// import { EmploiFaqComponent } from '../master_modules/masters/apps/screening_components/faq/emploi-faq/emploi-faq.component';
// import { NollieServicesHowweworkComponent } from '../master_modules/masters/apps/screening_components/service_sections/nollie-services-howwework/nollie-services-howwework.component';
// import { NollieTestimonialCustomersComponent } from '../master_modules/masters/apps/screening_components/testimonials/nollie-testimonial-customers/nollie-testimonial-customers.component';
// import { NollieOurValuesComponent } from '../master_modules/masters/apps/screening_components/our-values/nollie-our-values/nollie-our-values.component';
// import { BasicPricingComponentComponent } from '../master_modules/masters/apps/screening_components/pricing/basic-pricing-component/basic-pricing-component.component';
// import { AboutusCoreValuesComponent } from '../master_modules/masters/apps/screening_components/about-us/aboutus-core-values/aboutus-core-values.component';
// import { NollieCaseStudies } from '../master_modules/masters/apps/screening_components/case-studies/nollie-case-studies/nollie-case-studies.component';
// import { ExpertTeamComponent } from '../master_modules/masters/apps/screening_components/our-team/expert-team/expert-team.component';
// import { EmploiOurTeamComponent } from '../master_modules/masters/apps/screening_components/our-team/emploi-our-team/emploi-our-team.component';
// import { NollieOurTeamComponent } from '../master_modules/masters/apps/screening_components/our-team/nollie-our-team/nollie-our-team.component';
// import { EmploiHerobannerRightsideImageComponent } from 'simpo-components';
// import { EmploiJoinOurTeamComponent } from '../master_modules/masters/apps/screening_components/our-team/emploi-join-our-team/emploi-join-our-team.component';
// import { NollieBlogComponent } from '../master_modules/masters/apps/screening_components/blog-section/nollie-blog/nollie-blog.component';
// import { NollieHerobannerComponent } from 'simpo-components';
import { V2HorizontalFaqComponent, V2SimpleContactFormComponent, V2SimpleFooterComponent, NollieChooseUsComponent, NollieServiceTechnologyComponent, V2SimpleHerobannerButtonImageComponent, V2ProcessSparkImageSideComponent, V2ServicesectioneditorComponent, V2VerticleServiceSectionComponent, V2SingleHorizontalTestimonialComponent, V2SinglePageDoubleTestimonialComponent, V2SinleVerticleTestimonialComponent, V2HoverHorizontalServiceSectionComponent, V2ProcessNumberTextComponent, V2ProcessCountImageSideComponent, V2ProcessCountImageComponent, NollieServicesComponent, NollieContactUsComponent, V2OpenedFaqComponent, V2SliderFaqComponentsComponent, V2ContactusTextInputsComponent, V2SimpleNavbarPagelinksComponent, NollieFooterComponent, EmploiFaqComponent, NollieServicesHowweworkComponent, NollieTestimonialCustomersComponent, NollieOurValuesComponent, BasicPricingComponentComponent, NollieCaseStudies, NollieBlogComponent, NollieAboutUsComponent, V2AboutusComponent, V2AboutusWithBackgroundImageComponent, AboutusCoreValuesComponent, V2HerobannerStatImageComponent, V2HerobannerRatingReviewBgimgImageComponent, EmploiJoinOurTeamComponent, NollieOurTeamComponent, ExpertTeamComponent, EmploiOurTeamComponent, EmploiHerobannerRightsideImageComponent, V2HerobannerFeatureRightsideImageComponent, NollieHerobannerComponent, V2HerobannerStatImageReverseComponent, V2HerobannerWithReviewComponent, V2SimpleHeroBannerComponent, V2SimpleHerobannerRightsideBackgroundComponent, BlurredBgNotifyComponent, SimpoHerobannerComponent, SimpoTestimonialComponent, SimpoFeaturesComponent, SimpoCardComponent, SimpoCaseStudiesComponent, SimpoCtaActionComponent, SimpoPricingComponent, SimpoNavbarComponent, SimpoWebsiteGenerateComponent, SimpoFaqComponent, SimpoFooterComponent } from 'simpo-components';


@Injectable({
  providedIn: 'root'
})
export class DynamicpickcomponentsService {

  componentsReferences = Array<ComponentRef<any>>()

  constructor(
    private comFactoryResolver: ComponentFactoryResolver,
  ) { }

  listComponents: { name: string, component: any }[] = [
    //herobanners
    {
      name:'miscellaneous-blurred-classic',
      component:BlurredBgNotifyComponent
    },
    {
      name: 'our-team-minimalist',
      component: EmploiJoinOurTeamComponent
    },
    {
      name: 'hero-banner-image',
      component: EmploiHerobannerRightsideImageComponent
    },
    {
      name: 'hero-banner-minimalist',
      component: V2HerobannerFeatureRightsideImageComponent
    },
    {
      name: 'hero-banner-stats-standard',
      component: V2HerobannerRatingReviewBgimgImageComponent
    },
    {
      name: 'hero-banner-stats',
      component: V2HerobannerStatImageComponent
    },
    {
      name: 'hero-banner-stats-patterned',
      component: V2HerobannerStatImageReverseComponent
    },
    {
      name: 'hero-banner-modern',
      component: V2HerobannerWithReviewComponent
    },
    {
      name: 'hero-banner-standard',
      component: V2SimpleHeroBannerComponent
    },
    {
      name: 'hero-banner-gradient',
      component: V2SimpleHerobannerRightsideBackgroundComponent
    },
    {
      name: 'hero-banner-patterned',
      component: V2SimpleHerobannerButtonImageComponent
    },
    //process section components
    {
      name: 'process-classic',
      component: V2ProcessCountImageComponent
    },
    {
      name: 'process-modern',
      component: V2ProcessCountImageSideComponent
    },
    {
      name: 'process-standard',
      component: V2ProcessNumberTextComponent
    },
    {
      name: 'process-minimalist',
      component: V2ProcessSparkImageSideComponent
    },
    //service section components
    {
      name: 'service-modern',
      component: V2HoverHorizontalServiceSectionComponent
    },
    {
      name: 'service-image',
      component: V2ServicesectioneditorComponent
    },
    {
      name: 'service-minimal',
      component: V2VerticleServiceSectionComponent
    },
    //testimonials
    {
      name: 'testmonial-fullwidth',
      component: V2SingleHorizontalTestimonialComponent
    },
    {
      name: 'testimonial-minimalist',
      component: V2SinglePageDoubleTestimonialComponent
    },
    {
      name: 'testimonial-classic',
      component: V2SinleVerticleTestimonialComponent
    },
    //about us
    {
      name: 'about-us-standard',
      component: V2AboutusComponent
    },
    {
      name: 'about-us-modern',
      component: V2AboutusWithBackgroundImageComponent
    },
    //faq components
    {
      name: 'faq-standard',
      component: V2HorizontalFaqComponent
    },
    {
      name: 'faq-minimal',
      component: V2OpenedFaqComponent
    },
    {
      name: 'faq-minimal-slider',
      component: V2SliderFaqComponentsComponent
    },
    {
      name: 'nav-bar-standard',
      component: V2SimpleNavbarPagelinksComponent
    },

    //contact us
    {
      name: 'contact-us-standard',
      component: V2ContactusTextInputsComponent
    },
    {
      name: 'contact-us-modern',
      component: V2SimpleContactFormComponent
    },

    //blogs
    {
      name: 'blog-classic',
      component: NollieBlogComponent
    },

    //footers
    {
      name: 'footer-standard',
      component: V2SimpleFooterComponent
    },
    {
      name: 'footer-classic',
      component: NollieFooterComponent
    },
    {
      name: 'choose-stat',
      component:EmploiFaqComponent 
    },
    {
      name: 'choose-us-standard',
      component: NollieChooseUsComponent
    },
    {
      name: 'service-standard',
      component: NollieServicesComponent
    },
    {
      name: 'service-minimalist',
      component: NollieServiceTechnologyComponent
    },

    {
      name: 'contact-us-classic',
      component: NollieContactUsComponent
    },
    {
      name: 'hero-banner-classic',
      component: NollieHerobannerComponent
    },
    {
      name: 'about-us-classic',
      component: NollieAboutUsComponent
    },
    {
      name: 'choose-stat',
      component: EmploiFaqComponent
    },
    {
      name: 'service-classic',
      component: NollieServicesHowweworkComponent
    },
    {
      name: 'testimonial-standard',
      component: NollieTestimonialCustomersComponent
    },
    {
      name: 'our-values-standard',
      component: NollieOurValuesComponent
    },
    {
      name: 'pricing-standard',
      component: BasicPricingComponentComponent
    },
    {
      name: 'about-us-minimalist',
      component: AboutusCoreValuesComponent
    },
    {
      name: 'case-study-classic',
      component: NollieCaseStudies
    },
    {
      name: 'our-team-standard',
      component: ExpertTeamComponent
    },
    {
      name: "our-team-image",
      component: EmploiOurTeamComponent
    },
    {
      name: "our-team-classic",
      component: NollieOurTeamComponent
    },
    {
      name:"simpo-hero-banner",
      component:SimpoHerobannerComponent
    },
    {
      name:"simpo-testimonial",
      component:SimpoTestimonialComponent
    },
    {
      name:"simpo-feature",
      component:SimpoFeaturesComponent
    },
    {
      name:"simpo-card",
      component:SimpoCardComponent,
    },
    {
      name:"simpo-case-studies",
      component:SimpoCaseStudiesComponent
    },
    {
      name:"simpo-cta-action",
      component:SimpoCtaActionComponent
    },
    {
      name:"simpo-pricing",
      component:SimpoPricingComponent
    },
    {
      name:"simpo-navbar",
      component:SimpoNavbarComponent
    },
    {
      name:"simpo-website-generate",
      component:SimpoWebsiteGenerateComponent
    },
    {
      name:"simpo-faq",
      component:SimpoFaqComponent,
    },
    {
      name:"simpo-footer",
      component: SimpoFooterComponent,
    }


  ];


  componentsInstances: any[] = [];
  dyanmicComponentPickup(displaySection, items, edit, all) {

    this.componentsReferences = [];
    this.componentsInstances = [];
    displaySection.clear();
    let component = items.forEach((components, i) => {
      this.listComponents.forEach((localComponents, j) => {
        if (localComponents.name === components.webComponentName) {
          const componentFactory = this.comFactoryResolver.resolveComponentFactory(localComponents.component);
          const k = displaySection.createComponent(componentFactory);
          k.instance.responseData = components;
          // k.instance.edit = edit;
          // k.instance.all = all;
          k.instance.canEditAll = true;
          k.instance.index = i;
          k.instance.globalStyle = this.globalStyles;
          k.instance.id = components.category.name;
          this.componentsReferences.push(k);
          this.componentsInstances.push(k.instance._eventHelper);
        }
      });
    });
    return this.componentsInstances;
  }
  
  globalStyles ={
    'padding-left':"5%",
    'padding-right':"5%"
  }
  dyanmicComponentPickupPreview(displaySection, items, edit, all) {

    this.componentsReferences = [];
    this.componentsInstances = [];
    displaySection.clear();
    let component = items.forEach((components, i) => {
      this.listComponents.forEach((localComponents, j) => {
        if (localComponents.name === components.webComponentName) {
          const componentFactory = this.comFactoryResolver.resolveComponentFactory(localComponents.component);
          const k = displaySection.createComponent(componentFactory);
          k.instance.responseData = components;
          // k.instance.edit = edit;
          // k.instance.all = all;
          k.instance.canEditAll = false;
          k.instance.index = i;
          k.instance.globalStyle = this.globalStyles
          k.instance.id = components.category.name;
          this.componentsReferences.push(k);
          this.componentsInstances.push(k.instance._eventHelper);
        }
      });
    });
    return this.componentsInstances;
  }

  dyanmicComponentPickupSingleComponent(displaySection, itemDetail, canEditAll: boolean = false) {
    this.componentsReferences = [];
    displaySection?.clear();
    // let component = items.forEach((components, i) => {


    let component = this.listComponents.forEach((localComponents, j) => {
      if (localComponents.name === itemDetail.webComponentName) {
        const componentFactory = this.comFactoryResolver.resolveComponentFactory(localComponents.component);
        const k = displaySection.createComponent(componentFactory);
        k.instance.responseData = itemDetail;
        k.instance.canEditAll = canEditAll;
        k.instance.id = itemDetail.category.name;
        this.componentsReferences.push(k);
        // return k.instance;
      }
    });
    return component
    // });
  }

  addComponent(displaySection, item, edit, all, index) {
    let componentFact = this.listComponents.forEach((listCompos, i) => {
      if (listCompos.name === item.webComponentName) {
        const componentFactory = this.comFactoryResolver.resolveComponentFactory(listCompos.component);
        const k = displaySection.createComponent(componentFactory, index);
        k.instance.responseData = item;
        // k.instance.edit = edit;
        // k.instance.all = all;
        k.instance.canEditAll = true;
        k.instance.index = index;
        // k.instance.id = item.category.name;
        this.componentsReferences.splice(index, 0, k);
      }
    });
    return componentFact;
  }

  pickLoaderComponent(displaySection, component) {
    const componentFactory = this.comFactoryResolver.resolveComponentFactory(component)
    const k = displaySection.createComponent(componentFactory);

    return k;
  }

  replaceComponent(displaySection, oldItem, newItem, edit, all) {
    let a = this.listComponents.forEach((listComps, i) => {
      // if(listComps.name === oldItem.webComponentName){
      //   let componentClass = listComps.component
      //   const component = this.componentsReferences.find((component) => component.instance instanceof componentClass);
      //   if(!component) return ;
      //   const index = this.componentsReferences.indexOf(component);
      // const componentFactory = this.comFactoryResolver.resolveComponentFactory(listComps.component);
      // const k = displaySection.createComponent(componentFactory, index);

      //   if(index !== -1){
      //     displaySection.splice(index,1,k);
      //     this.componentsReferences.splice(index,k)
      //   }
      // }
      if (listComps.name === oldItem.webComponentName) {
        let oldCompClass = listComps.component;
        const component = this.componentsReferences.find((component) => component.instance instanceof oldCompClass);
        if (!component) return;
        const oldCompIndex = this.componentsReferences.indexOf(component);
        if (listComps.name === newItem.webComponentName) {
          let newCompClass = listComps.component;
          const componentFactory = this.comFactoryResolver.resolveComponentFactory(newCompClass);
          const k = displaySection.createComponent(componentFactory, oldCompIndex);
          this.componentsReferences.splice(oldCompIndex, 1, k)
        }
      }
    });
    return a;
  }

  componentPositionSwap(data, displaySection) {
    if (data.movingDirection === 'DOWN') {
      let indexA = data.index;
      let indexB = data.index + 1;

      let viewA = displaySection.get(indexA);
      let viewB = displaySection.get(indexB);

      // move the views to the opposite positions
      let a = displaySection.move(viewA, indexB) && displaySection.move(viewB, indexA) &&
        this.componentsReferences[indexA] &&
        this.componentsReferences.splice(indexB, 1, this.componentsReferences[indexA])[0];
      return a;
    }
  }

  removeComponent(displaySection, item) {
    let a = this.listComponents.forEach((listComps, i) => {
      if (listComps.name === item.webComponentName) {
        let componentClass = listComps.component
        const component = this.componentsReferences.find((component) => component.instance instanceof componentClass);
        if (!component) return;
        const index = this.componentsReferences.indexOf(component);

        if (index !== -1) {
          displaySection.remove(index);
          this.componentsReferences.splice(index, 1)
        }
      }
    });
    return a;
  }

  addNewPage(displaySection, item, edit, all) {
    let component = item.forEach((components, i) => {
      this.listComponents.forEach((localComponents, j) => {
        if (components.webComponentName === localComponents.name) {
          const componentFactory = this.comFactoryResolver.resolveComponentFactory(localComponents.component);
          const k = displaySection.createComponent(componentFactory);
          k.instance.responseData = components;
          // k.instance.edit = edit;
          // k.instance.all = all;
          k.instance.canEditAll = true;
          k.instance.index = i;
        }
      });
    });
    return component;
  }

  samePageRedirection(selectedPage, id) {
    let a = document.querySelectorAll('*[id]');
    let b;
    a.forEach((singleNode) => {
      if (singleNode.id === selectedPage.components[id].category.name) {
        b = document.getElementById(singleNode.id);
        if (!b) return;
        b.scrollIntoView({ behavior: 'smooth' });
      }
    });
    return b;
  }
  externelUrls(newPage) {
    window.open(newPage, '_blank')
  }
}
