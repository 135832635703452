<section class="main-section">
  <!-- header-section  -->
  <div class="main-header-section">

      <!-- header-left-section  -->
      <div class="header-left-section">
          <div class="header-left-main-heading">
              <div class="back_btn">
                  Components
                </div>
          </div>
      </div>
      <!-- header-right-section  -->
      <div class="header-right-section">
          <div class="close_btn" (click)="backToHome()">
              <mat-icon>close</mat-icon>
          </div>
      </div>
  </div>
  <div class="center_section">
      <div class="other_section"></div>
      <div class="search-bar taking_input">
          <mat-form-field class="sfull-width domain-input" appearance="outline">
              <input matInput placeholder="Search by page name" [(ngModel)]=searchText  />
              <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
          <div class="chips_container" *ngIf="chipsArray.length != 0">
            <ng-container *ngFor="let item of chipsArray">
                <div class="single_chip" >
                    <div class="chip_title">
                        {{item.pageName}}
                    </div>
                    <div class="cross_btn" (click)="closeChip(item)">
                        <img loading="lazy" src="./../../../../../../../../assets/images/master/cross.svg" alt="cross_btn">
                    </div>
                </div>
            </ng-container>
        </div>
      </div>
  </div>
  <div class="section_body">
      <div class="body_section_left_part">
          <div class="center_tab">
              <ng-container *ngFor="let item of categoryTypes">
                  <div [ngClass]="item.status ? 'single_key_active' : 'single_key'"
                  (click)="changingWebComponentValues(item.name)">{{item.name}}</div>
              </ng-container>
          </div>
      </div>
      <div class="body_section_right_part">
          <div class="pages_list">
              <ng-container *ngFor="let item of allPages">
                  <div class="container-box">
                      <div class="container" (click)="selectPage(item)" [class.selected]="item.isSelected">
                          <img  [src]="item.screenShot != null ? item.screenShot : '../../../../../../assets/images/master/7.png'" alt="" class="image">
                              <div class="overlay">
                                  <span class="overlay_title">{{item.pageName | titlecase}}</span> &nbsp;&nbsp;
                                  <span class="overlay_title">{{item.subIndustry.name | titlecase}}</span>
                              </div>
                      </div>
                      <div class="simpoName">{{item.simpoName | titlecase}}</div>
                  </div>
              </ng-container>
          </div>
      </div>
  </div>
  <div class="bottom_section">
      <div class="submit_btn">
          <button (click)="createTemplate()">
              <span *ngIf="submitLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
              <span *ngIf="!submitLoader">Submit</span>
          </button>
      </div>
  </div>
</section>
