<div class="pageSize">
    <div class="paddingForPage">
        <div class="title">
            Forgot Password
            <mat-icon (click)="close()">close</mat-icon>
        </div>
        <div class="hr-line"></div>
        <div class="description">
            Please enter your mail id
        </div>
        <!-- <input class="inputBoxStyle" type="text" placeholder="Enter domain url"> -->
        <div class="form_field business-field-section">
            <mat-form-field class="sfull-width" appearance="outline">
                <input placeholder="jhon.smith@gmail.com" matInput [(ngModel)]="mailId"/>
            </mat-form-field>
        </div>
        <div class="btnRightAlign">
            <button class="buttonStyle" (click)="submit()">
                <span *ngIf="!loader">
                    Submit
                </span>
                <span *ngIf="loader">
                    <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                </span>
            </button>
        </div>
    </div>
</div>