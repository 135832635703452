<section class="main_section" *ngIf="!getLoader">
    <div class="header">
        <div class="header-text">Template</div>
        <div class="close-image">
            <div class="combine_section">
                <div  class="action-icons">
                    <img src="./../../../../../../../assets/images/master/delete.svg" alt=""
                    (click)="deleteComponent(templateData?.template.id)">
                </div>
                <div class="hr_line"></div>
                <div class="action-icons">
                    <img src="./../../../../../../../assets/images/master/edit.svg" alt=""
                    (click)="editComponent(templateData?.template.id)">
                </div>
            </div>
            <div  class="action-icons_1" (click)="cancel()">
                <img src="../../../../../../../../../../assets/images/close.svg" alt="">
            </div>
        </div>
    </div>
    <hr class="section-divider">
    <div class="registration">
        <div class="registrtaion-img">
            <img [src]="templateData?.template.screenShot != null ? templateData?.template.screenShot : '../../../../../../../../assets/images/master/11.png'" alt="">
        </div>

    </div>
    <div class="registration-txt">
        <div class="simpo_template_name">{{templateData?.template.name | titlecase}}</div>
        <div class="all_types">
            <div class="text">
                <div class="header_text_1">
                    Business Sub Industry
                </div>
                <div class="header_value">
                    {{templateData?.template.subIndustry?.name | titlecase}}
                </div>
            </div>
        </div>
        <!-- <div class="text">
            <div class="header-text_1">Simpo Page Name</div> : <div class="header-value">{{ data?.pageData?.simpoName | titlecase}}</div>
        </div>
        <div class="text">
            <div class="header-text_1">Category</div> : <div class="header-value">{{ data?.pageData?.subIndustry?.name}}</div>
        </div> -->
        <!-- <div class="header-text">Reference URl :  {{ data?.data?.refUrl}}</div> -->
        <!-- <div class="description"> {{ data?.pageData?.category?.name}} &nbsp;&nbsp;{{ data?.data?.subIndustries?.name}}</div> -->
    </div>



</section>
