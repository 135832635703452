import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-rest-password',
  templateUrl: './rest-password.component.html',
  styleUrls: ['./rest-password.component.scss']
})
export class RestPasswordComponent implements OnInit {

  constructor(
    private _authService : AuthServiceService,
    private _snakBar : MatSnackBar,
    private _router : Router
  ){}

  password : any = '';
  confirmPassword : any  = '';
  staffId : any = '';
  emailId : any = '';
  passwordHide = true;
  cnfirmPasswordHide = true;
  loader : boolean = false;

  ngOnInit(): void {
    this.checkParams();
  }

  // password

  pwd : FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  get passwordInput() {
    return this.pwd.get('password');
  }

  //confirm password
  cnfirmPwd:FormGroup = new FormGroup({
    confirmPassword: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  get confirmPasswordInput() {
    return this.cnfirmPwd.get('password');
  }

  checkParams(){
    // let url = window.location.href.split("?")[1];
    // let queryString = new URLSearchParams(url);
    // for (let pair of queryString.entries()) {
    //   if(pair[0] === 'uid'){
    //     this.staffId = pair[1];
    //   }
    //   if(pair[0] === 'email'){
    //     this.emailId = pair[1];
    //   }
    // }
  }

  confirmPasswordBoolean : boolean = false;

  checkingConfirmPassword(){
    if(this.password === this.confirmPassword){
      this.confirmPasswordBoolean = false;
    }
    else{
      this.confirmPasswordBoolean = true;
    }
  }

  submit(){
    this.loader = true;
    if(this.password === this.confirmPassword){
      let data = {
        staffId:this.staffId,
        newPassword:this.confirmPassword,
        emailId:this.emailId
      }
  
      this._authService.resetPassword(data).subscribe(
        (res:any) => {
          this._snakBar.open(
            'Password Changed Successfully',
            'Ok',
            {
              duration:1500
            }
          );
          this._router.navigate(['/login']);
          this.loader = false;
        },
        (err:any) => {
          this._snakBar.open(
            'Something went wrong, Please try again',
            'Close',
            {
              duration:1500
            }
          );
          this.loader = false;
        }
      )
    }
    else{
      this.confirmPasswordBoolean = true;
      this.loader = false;
    }
  }
}
