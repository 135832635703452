    <div class="signup_section">
      <div class="signup-left-section">
        <div class="header-website">
          <div class="close-image">
            <!-- <mat-icon>keyboard_arrow_left</mat-icon> -->
              <img src="./../../../../assets/images/logo.svg"/>
          </div>
        </div>
        <div class="login_body">
          <!-- <div class="company_logo">
            <img src="./../../../../assets/images/named-logo.svg" alt="logo">
          </div> -->
          <div class="section_title">Welcome back!</div>
          <div class="section_subtitle">Login to simpo.ai account</div>
          <div class="fields">
            <div class="field">
              <div class="label">Email Address or Mobile Number</div>
              <mat-form-field appearance="outline" class="taking_input">
                <input matInput placeholder="Enter Email Address or Mobile Number" [(ngModel)]="businessDetails.contact.email"/>
              </mat-form-field>
            </div>
            <div class="field">
              <div class="label">Password</div>
              <form [formGroup]="signin">
                <mat-form-field class="taking_input" appearance="outline">
                  <input matInput placeholder="Enter Password" [type]="signinHide ? 'password' : 'text'" formControlName="password"
                  minlength="8" [(ngModel)]="businessDetails.pwd" (keyup.enter)="login()"/>
                  <mat-icon matSuffix (click)="signinHide = !signinHide" class="eye-icon">{{signinHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
              </form>
              <div class="remember_forget">
                <div class="remeber">
                  <!-- <mat-checkbox class="example-margin">Remember me</mat-checkbox> -->
                </div>
                <div class="forgot_password" (click)="forgotPassword()">Forgot password</div>
              </div>
            </div>
          </div>
          <!-- <div class="term_conditions">
              By clicking "Log in" you agree to simpo.ai <span style="color:#0267C1;cursor: pointer;" (click)="privacyPolicy()">Privacy Policy</span> and <span style="color: #0267C1;cursor: pointer;" (click)="termsConditions()"> Terms of Service</span>.
          </div> -->
          <div class="continue_btn">
            <button (click)="login()">
              <span *ngIf="!loader">Login</span>
                <span class="spinner" *ngIf="loader">
                  <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                </span>
            </button>
          </div>
          <!-- <div class="continue">
            <img src="./../../../../assets/images/common/or_continue.svg" alt="continue">
          </div>
          <div class="google">
            <img src="./../../../../assets/images/common/continue_google.svg" alt="continue_google">
          </div> -->
          <!-- <div class="alrea_account">
            No account yet? <span style="color:#0267C1;cursor: pointer;" (click)="redirectToSignup()">Sign Up</span>
          </div> -->
        </div>
      </div>
      <div class="signin_right_section">
        <img src="./../../../../assets/images/common/login_image.svg" alt="signup_image">
      </div>
  </div>
   <!-- </div> -->