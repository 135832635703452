import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateMasterService } from '../master_modules/masters/apps/template/template-master.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategorySelectionTemplateComponent } from '../master_modules/masters/apps/template/templates/popups/category-selection-template/category-selection-template.component';
import { V3PageSelectionPopupComponent } from '../v3-page-selection-popup/v3-page-selection-popup.component';
import { PreviewRightsideTemplateComponent } from '../master_modules/masters/apps/template/templates/popups/preview-rightside-template/preview-rightside-template.component';

@Component({
  selector: 'app-v3-templates',
  templateUrl: './v3-templates.component.html',
  styleUrls: ['./v3-templates.component.scss']
})
export class V3TemplatesComponent {

  constructor(
    private router: Router,
    private templateMasterService: TemplateMasterService,
    private _snackbar: MatSnackBar
  ) {}

  title = 'ngSlick';
  totalPages: any[] = [];
  totalPagesCount: any;
  searchParameter: any = '';

  slides: any[] = [];

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
          fade: true,
          cssEase: 'linear',
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  addSlide() {
    this.slides.push(488);
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e: any) {
    // console.log('slick initialized');
  }

  breakpoint(e: any) {
    // console.log('breakpoint');
  }

  afterChange(e: any) {
    // console.log('afterChange');
  }

  beforeChange(e: any) {
    console.log('beforeChange');
  }

  ngOnInit() {
    this.listAllTemplates();
  }
  dataSource: any = [];
  components: any = [];

  addTemplate() {
    let openDialog = this.templateMasterService.openDialogWithData(
      CategorySelectionTemplateComponent,
      '300px',
      '460px',
      { data: 'ADD_TEMPLATE' }
    );

    openDialog.afterClosed().subscribe((res: any) => {
      if (res.data != null) {
        let listContainer = this.templateMasterService.openDialogWithData(
          V3PageSelectionPopupComponent,
          '100vh',
          '100%',
          { data: { basicDetails: res.data, from: 'FROM_ADD' } }
        );

        listContainer.afterClosed().subscribe((res: any) => {
          if (res) {
          }
        });
      }
    });
  }

  showAllPages(id) {
    this.router.navigate([`/master/template/all-templates/${id}`]);
  }
  componentSource: any = [];
  gettingLoader: boolean = false;
  listAllTemplates() {
    this.gettingLoader = true;
    this.templateMasterService.v3ListTemplates().subscribe(
      (res: any) => {
        console.log(res.data);
        // this.totalPagesCount = res.data.length;
        this.totalPages = res.data;
        this.slides = res.data;
        this.gettingLoader = false;
      },
      (err) => {
        this.gettingLoader = false;
        this._snackbar.open(`${err.error.message}`, 'Close', {
          duration: 1500,
        });
      }
    );
  }

  showPreviewPage(item) {
    let dialog = this.templateMasterService.openDialogWithData(
      PreviewRightsideTemplateComponent,
      '100vh',
      '40%',
      { pageData: item }
    );
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.listAllTemplates();
      }
    });
  }

  searchTemplate() {
    this.templateMasterService.searchTemplate(this.searchParameter).subscribe(
      (res: any) => {
        // console.log(res.data)
        this.totalPages = res.data;
        this.slides = res.data;
      },
      (err) => {
        this._snackbar.open(`${err.error.message}`, 'Close', {
          duration: 1500,
        });
      }
    );
  }
}
