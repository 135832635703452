<section class="main-section" *ngIf="!gettingLoader;">
  <div class="top_header_section">
    <!-- header-section  -->
    <div class="main-header-section">
      <!-- header-left-section  -->
      <div class="header-left-section">
        <div class="header-left-main-heading">
          Templates
        </div>
        <div class="header-left-sub-heading">
          Used to generate website templates based on business industry
        </div>
      </div>
      <!-- header-right-section  -->
      <div class="header-right-section">
        <div class="add-business-section" (click)="addTemplate()">
          + Create Template
        </div>
      </div>
    </div>
    <!-- search and filter section  -->
    <div class="search-filter-main-section">
      <div class="search-bar">
        <mat-form-field class="sfull-width" appearance="outline">
          <input matInput placeholder="Search for Template name" [(ngModel)]="searchParameter"
            (keyup)="searchTemplate()" />
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="bottom_section">
    <div *ngIf="totalPages != null">
      <ng-container div *ngFor="let item of totalPages | keyvalue">
        <div class="component-header-section">
          <div class="header-left-section">
            {{item?.key}} <span class="header-span">{{totalPages[item.key].length}}
              <span *ngIf="totalPages[item.key].length === 1">Tempalte</span>
              <span *ngIf="totalPages[item.key].length != 1">Templates</span>
            </span>
          </div>
          <div class="header-right-section" (click)="showAllPages(totalPages[item.key][0].subIndustry.id)"
            *ngIf="totalPages[item.key].length > 3">
            See All
          </div>
        </div>
        <div class="list-templates">
          <ng-container *ngFor="let item of totalPages[item.key]; let i = index">
            <div class="single_template" *ngIf="i<3" (click)="showPreviewPage(item)">
              <div class="template_image">
                <img [src]="item.screenShot != null ? item.screenShot : '../../../../../../assets/images/master/7.png'"
                  alt="">
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div *ngIf="totalPages === null" class="empty_page">
      <div class="empty_text">
        You don't have any templates, yet. Let's fix that!
        Click on <span style="color:#0267c1">+ Create template</span> above
      </div>
    </div>
  </div>
</section>
<section class="main-section" *ngIf="gettingLoader">
  <div class="img-section">
    <img src="./../../../../../../assets/images/loader/beeos_loader.gif" alt="loader.svg" />
  </div>
</section>
