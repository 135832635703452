// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl:'https://api.simpo.ai/',
//  baseUrl: 'https://dev-api.simpo.ai/', //'http://localhost:8765/',
   baseUrl : 'https://stage-api.simpo.ai/',
  // baseUrl: 'https://dev-api.proficornlabs.com/',
  // serverIp: 'https://dev-admin.simpo.ai/',
  serverIp:"https://stage-admin.simpo.ai/",
  // urlEndingPoint: 'dev.simpo.ai',
  urlEndPoint:"stage.simpo.ai",
  redirectingUrl: 'https://stage-web.simpo.ai',
  // redirectingUrl: 'https://dev-web.simpo.ai',
  // redirectingUrl :'http://localhost:4200',
  // regenerateUrl : 'https://stage-admin.simpo.ai/',
  // regenerateUrl : 'https://dev-admin.simpo.ai/',
  regenerateUrl : 'https://stage-admin.simpo.ai',
  //firebase configuration
  // firebase: {
  //   apiKey: "AIzaSyB0uWmX7n-GjrZOh0KRXtGG9bgrO0R9fYI",
  //   authDomain: "beeos-d3a05.firebaseapp.com",
  //   projectId: "beeos-d3a05",
  //   storageBucket: "beeos-d3a05.appspot.com",
  //   messagingSenderId: "264451984597",
  //   appId: "1:264451984597:web:df34ef53cc1f822b5aa65c",
  //   measurementId: "G-PCTKBE8GCX"
  // },

  firebase: {
    // for facebook login test as prateek has access for stage proficon firebase
    apiKey: 'AIzaSyApQ2YPg4Gz7cG567EnpooA_lhqekfKsME',
    authDomain: 'stage-proficornlabs.firebaseapp.com',
    projectId: 'stage-proficornlabs',
    storageBucket: 'stage-proficornlabs.appspot.com',
    messagingSenderId: '801572740852',
    appId: '1:801572740852:web:44e143c44a0dd5c8a588a1',
    measurementId: 'G-MW1VEYQ1KF',
  },

  //compoonents image uploading
  componentImageUploading: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'us-east-1:d4bc770a-5664-4051-bd66-6861a6efbd9c',
          Region: 'us-east-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'dev-beeos',
        Region: 'us-east-1',
      },
    },
  },

  //mapBox for google maps
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibmFpZHUyNTA3IiwiYSI6ImNsZWxjcGlmZTB0ejkzb3BnMndzbmthM2cifQ.BJoCPnd81NYvx_2VUadF2w',
  },

  //goDaddy SSO Key for dev environment
  goDaddy: {
    ssoKey:
      'sso-key 3mM44UcgzgNc8W_PW9i1fz5K8SYbVT1Mzg1i:PW2vd4zTxF8orAqBYD9VXs',
  },
  // Unsplash access key
  unsplash: {
    UNSPLASH_API_TOKEN: 'iMn43HCZ_FLCMWkZgUtMAjt-p-M6vmaB1Z_3fbetmJA',
  },
  // Shutterstock access key
  shutterstock: {
    SHUTTERSTOCK_API_TOKEN:
      'v2/c2Q4Nms0MG9MQTBlS1RnbjE1OUlXcTFUeDNOclR6aGcvNDA3MTA0OTQxL2N1c3RvbWVyLzQvMUtieG1GVjRscWNBZWZQRzMxLTFGRHM4RzdNZVZRaU5JcEFNbE4wTTROZm5Ua1ZPZWlPZjV4WElmYkZ5MmstQ1pDT2JVS0V6aFE2TG1jOF9nUm55RmV5WVExd2diVXI5ZWNnbWQ3aFUtd2NDb0lFcEYwVmF6OHl0LTBTbXNSMnVpLXNUNWpieWtnLTRxU1J4cUlxeVFUWVctU1FkaWM2OXFpT0FsbG9PMkpTQWhzTEtBTk1KOFBzems2YTQtMFZMcHhWc2xrN0EwVXg2b2RTcHBFSndXUS95UkRkNE9YNm5sdV9Bc1N5S3FmUV9R',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
