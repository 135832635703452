import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateMasterService } from '../../../template-master.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-delete-template',
  templateUrl: './delete-template.component.html',
  styleUrls: ['./delete-template.component.scss']
})
export class DeleteTemplateComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private templateMasterService: TemplateMasterService,
    private _snackbar : MatSnackBar

  ){

  }

  typeId : any ;
  ngOnInit() {
    console.log(this.data)
    this.typeId = this.data.data;
  }

  deleteIconLoader : boolean = false;
  deleteComponent(){
    this.deleteIconLoader = true;
    if(this.typeId === 'DELETE_PAGE'){
      this.dialogRef.close('DELETE_PAGE')
    }
    else{
      this.templateMasterService.deleteTemplate(this.typeId).subscribe({
        next:(res:any)=>{
            this._snackbar.open(
              `Successfully template deleted`,
              'Ok',
              {
                duration:1500
              }
            )
            this.dialogRef.close('success');
            this.deleteIconLoader = false
        },
        error:(err)=>{
          this.deleteIconLoader = false;
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          )
        }
      })
    }
  }


  closeBtn(){
    this.dialogRef.close();
  }
}
