import { Component, Inject, OnInit } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';
import { TemplateMasterService } from '../../../template-master.service';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-category-selection-template',
  templateUrl: './category-selection-template.component.html',
  styleUrls: ['./category-selection-template.component.scss']
})
export class CategorySelectionTemplateComponent implements OnInit{
  
  filteredOptions: Observable<string[]> | any;
  myControl = new FormControl();
  allTypes: any = [];
  templateName : any = '';

  constructor(
    private _templateService : TemplateMasterService,
    private snackbar : MatSnackBar,
    public dialogRef: MatDialogRef<CategorySelectionTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
  }

  ngOnInit(): void {
    console.log(this.data.data)
    this.getAllTypes()
  }

  getAllTypes() {
    this._templateService.mergedIndustrySubIndustry().subscribe(
      (res: any) => {
        //console.log(res.data);
        this.filteredOptions = this.myControl;
        // this.allTypes = res.data;
        res.data.forEach((data: any) => {
          this.allTypes.push({
            name: data.parent.name + ' - ' + data.name,
            industryId: data.parent.id,
            subIndustryId: data.id,
            industryName: data.parent.name,
            subIndustryName: data.name,
          });
        });
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      },
      (err) => {
        // ////console.log(err)
        this.snackbar.open(`${err.error.message}`, 'Close');
      }
    );
  }
  private _filter(value: any) {
    const filterValue = value.toLowerCase();

    return this.allTypes.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  subCategoryId:any="";
  bType : any;
  selectBusinessType(ev: any) {
    //console.log(ev);
    this.bType = ev;
  }
  getVal(ev){
    if(ev.length === 0){
      this.bType = '';
    }

  }

  close(){
    this.dialogRef.close({data:null})
  }


  submitLoader : boolean = false;
  submit(){
    if(this.bType != undefined && this.templateName != ''){
      // if(this.data.data === 'ADD_PAGE'){
      //   this.submitLoader = true;
      // let data = {
      //   id : this.bType.subIndustryId,
      //   name: this.bType.subIndustryName
      // }
  
      // this._templateService.createPage(data).subscribe(
      //   (res:any) => {
      //     console.log(res.data);
      //     this.dialogRef.close({data:res.data})
      //     this.submitLoader = false
      //   },
      //   (err) => {
      //     this.submitLoader = false;
      //     this.snackbar.open(
      //       err.error.message,
      //       'Close',
      //       {
      //         duration:1500
      //       }
      //       )
      //     }
      //   )
      // }
      // else{
      //   this.dialogRef.close({subIndustryId:this.bType.subIndustryId,subIndustryName:this.bType.subIndustryName})
      // }
      this.dialogRef.close({data:{bType:this.bType,tempName:this.templateName}})
    }
    else{
      this.snackbar.open(
        'Please fill mandatory fields to proceed',
        'Ok',
        {duration:1500}
      )
    }
  }
}
