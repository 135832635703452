import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _authService : AuthServiceService,
    private _snakBar : MatSnackBar,
    private _router : Router
  ){
    this.staffId = data.staffId;
  }
  
  password : any = '';
  confirmPassword : any  = '';
  staffId : any = '';
  emailId : any = '';
  passwordHide = true;
  cnfirmPasswordHide = true;
  currentPassword : any = '';
  currentPasswordHide = true;
  loader : boolean = false;

  ngOnInit(): void {
  }

  // password

  currentPwd : FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  get currentPasswordInput() {
    return this.currentPwd.get('password');
  }

  // password

  pwd : FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  get passwordInput() {
    return this.pwd.get('password');
  }

  //confirm password
  cnfirmPwd:FormGroup = new FormGroup({
    confirmPassword: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  get confirmPasswordInput() {
    return this.cnfirmPwd.get('password');
  }

  confirmPasswordBoolean : boolean = false;

  // checkingConfirmPassword(){
  //   if(this.password === this.confirmPassword){
  //     this.confirmPasswordBoolean = false;
  //   }
  //   else{
  //     this.confirmPasswordBoolean = true;
  //   }
  // }

  submit(){
    if(this.password === this.confirmPassword){
      this.loader = true;
      let data = {
        staffId:this.staffId,
        newPassword:this.confirmPassword,
        oldPassword:this.currentPassword
      }
  
      // this._authService.changePassword(data).subscribe(
      //   (res:any) => {
      //     this._snakBar.open('Password Changed Successfully','Ok',
      //       {
      //         duration:1500
      //       }
      //     );
      //     // this._router.navigate(['/login']);
      //     window.localStorage.clear();
      //     this._router.navigate(['/login'])
      //     this.loader = false;
      //     this.dialogRef.close();
      //   },
      //   (err:any) => {
      //     this._snakBar.open(
      //       'Something went wrong, Please try again',
      //       'Close',
      //       {
      //         duration:1500
      //       }
      //     );
      //     this.loader = false;
      //   }
      // )
    }
    else{
      this._snakBar.open('Confirm Password is not matching','Close',
        {
          duration:1500
        }
      );
    }
  }

  cancel(){
    this.dialogRef.close();
  }

}
