import { Component, Inject, OnInit } from '@angular/core';
import { TemplateMasterService } from '../master_modules/masters/apps/template/template-master.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-v3-page-selection-popup',
  templateUrl: './v3-page-selection-popup.component.html',
  styleUrls: ['./v3-page-selection-popup.component.scss']
})
export class V3PageSelectionPopupComponent {
  constructor(
    private templateMasterService: TemplateMasterService,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<V3PageSelectionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public response: any,
    private _snackbar: MatSnackBar,
    private router: Router
  ) { }

  allPages: any = [];
  totalResponse: any = [];
  categoryName: any;
  categoryTypes: any = [];
  data: any;
  chipsArray: any[] = []
  fromData: any;
  ngOnInit(): void {
    this.data = this.response.data
    console.log(this.response.data);
    this.listPages()
  }

  dataSource: any = [];



  page: number = 0;
  size = 100000;
  searchText: any;
  emptyloader: boolean = false;



  sendingPagesId: any[] = [];


  selectPage(element: any) {
    console.log(element);
    // if(this.chipsArray.length != 0){
    //   if(this.chipsArray.find((item) => item.id === element.id)){
    //     this.snackbar.open(
    //       'This page is already selected',
    //       'Ok',
    //       {
    //         duration:1500
    //       }
    //     );
    //   }
    //   else{
    //     this.chipsArray.push({name:element.name,id:element.id})
    //     this.sendingPagesId.push(element.id);
    //   }
    // }
    this.sendingPagesId.push(element.id);
    element.isSelected = !element.isSelected;
  }
  componentSource: any = [];
  listPages() {
    this.templateMasterService.v3GetListAllPagesWithSubIndustry(this.data.basicDetails.bType.subIndustryId).subscribe(
      (res: any) => {
        console.log(res.data)
        this.totalResponse = res.data
        Object.keys(this.totalResponse).forEach((key: any) => {
          this.categoryTypes.push({ name: key, status: false })
        })
        let a: any = Object.values(res.data)[0]
        this.categoryName = a[0].subIndustry.name;
        this.changingWebComponentValues(this.categoryTypes[0].name)
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
  }
  changingWebComponentValues(item) {
    Object.keys(this.totalResponse).forEach(key => {
      if (key === item) {
        this.allPages = this.totalResponse[key];
      }
    });
    this.categoryTypes.forEach((wk) => {
      if (wk.name === item) {
        wk.status = true
      }
      else {
        wk.status = false
      }
    });
  }

  backToHome() {
    this.dialogRef.close({ data: null });
  }

  closeChip(item) {
    this.chipsArray.forEach((element, i) => {
      if (element.id === item.id) {
        this.chipsArray.splice(i, 1)
      }
    });
    this.sendingPagesId.forEach((pid, j) => {
      if (item.id === pid) {
        this.sendingPagesId.splice(j, 1)
      }
    });
  }

  submitLoader: boolean = false;
  createTemplate() {
    if(this.data.form === 'FROM_EDIT'){
      this.dialogRef.close({pageIds:this.sendingPagesId});
    }
    else{
      this.submitLoader = true;
    let data = {
      name: this.data.basicDetails.tempName,
      subIndustry: {
        id: this.data.basicDetails.bType.subIndustryId,
        name: this.data.basicDetails.bType.subIndustryName
      },
      pageIds: this.sendingPagesId
    }
    // console.log(data)
    this.templateMasterService.v3CreateTemplate(data).subscribe(
      (res: any) => {
        this.snackbar.open(
          'Template created successfully',
          'Ok',
          {
            duration: 1500
          }
        );
        this.dialogRef.close()
        this.router.navigate([`/master/template/view-template/${res.data.template.id}`])
        this.submitLoader = false;
      },
      (err) => {
        this.submitLoader = false;
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
  }
}
}
