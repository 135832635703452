import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { GoogleAuthProvider } from 'firebase/auth';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  constructor(
    private http: HttpClient,
    // public afAuth: AngularFireAuth // Inject Firebase auth service
  ) {}

  // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new GoogleAuthProvider()).then((data: any) => {
  //     //console.log(data);
  //   });
  // }
  // Auth logic to run auth providers
  // AuthLogin(provider: any) {
  //   return this.afAuth['signInWithPopup'](provider)
  //     .then((result) => {
  //       //console.log('You have been successfully logged in!',result);
  //     })
  //     .catch((error) => {
  //       //console.log(error);
  //     });
  // }

  createAccount(data: any) {
    return this.http.post(environment.baseUrl + `staff/staff/business`, data);
  }

  getDetailsByBusinessId(businessId: any) {
    return this.http.get(
      environment.baseUrl + `business/business/${businessId}`
    );
  }
  login(data: any) {
    return this.http.post(environment.baseUrl + `staff/staff/admin/login`, data);
  }


  forgotPassword(data){
    return this.http.put(
      environment.baseUrl+`staff/staff/password/forgot?email=${data.email}`,data
    )
  }

  resetPassword(data){
    return this.http.put(
      environment.baseUrl+`staff/staff/password/reset`,data
    )
  }

  getAllCountries(){
    return this.http.get(
      environment.baseUrl+`admin/master/location/country?size=1000&page=0`
    )
  }

  getCurrencySymbol(businessId:any){
    return this.http.get(
      environment.baseUrl+`business/business/preference?businessId=${businessId}`
    )
  }
}
