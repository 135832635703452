<section class="main_section">
    <div class="section_top_header">
        <div class="section_top_header_text">
            New Template
        </div>
        <div class="close_icon" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="section_body">
        <div class="taking_input">
            <div class="lead_title">Template Name <sup>*</sup></div>
            <mat-form-field class="sfull-width domain-input" appearance="outline">
                <input matInput placeholder="Enter Template Name " [(ngModel)]="templateName"/>
            </mat-form-field>
        </div>
        <div class="taking_input dropdown_input">
            <div class="lead_title">Business Sub-Industry <sup>*</sup></div>
            <mat-form-field class="sfull-width domain-input" appearance="outline">
                <mat-icon matPrefix><mat-icon>search</mat-icon></mat-icon>
                <input type="text" placeholder="Enter business type" 
                matInput 
                [formControl]="myControl" 
                [matAutocomplete]="auto1"
                (ngModelChange)="getVal($event)"/>
                <mat-autocomplete #auto1="matAutocomplete">
                  <mat-option *ngFor="
                      let option of filteredOptions | async;
                      index as i
                    " [value]="option.name"
                    (click)="selectBusinessType(option)"
                    (keyup.enter)="selectBusinessType(option)"
                    id="searchValue"
                    >
                    {{ option.name}}
                  </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="submit_btn">
            <button (click)="submit()">
                <span *ngIf="submitLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                <span *ngIf="!submitLoader">Submit</span>
            </button>
        </div>
    </div>
</section>