import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SigninSignupComponent } from './signin-signup/signin-signup/signin-signup.component';
import { RestPasswordComponent } from './signin-signup/rest-password/rest-password.component';
import { ForgotPasswordComponent } from './signin-signup/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './signin-signup/change-password/change-password.component';
import { ConstantMaterialModule } from './material_module/constant-material.module';
import { PreviewComponent } from './preview/preview.component';
import { LazyImgDirective } from './lazy-img-directive.directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { V3TemplatesComponent } from './v3-templates/v3-templates.component';
import { V3PageSelectionPopupComponent } from './v3-page-selection-popup/v3-page-selection-popup.component';
// import { LottieLoaderComponent } from './shared-module-files/components/lottie-loader/lottie-loader.component';
// import { LottiePoppperComponent } from './shared-module-files/components/lottie-poppper/lottie-poppper.component';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}
// import { SimpleTestimonialCardV2Component } from './master_modules/masters/apps/elements/testimonial_cards/simple-testimonial-card-v2/simple-testimonial-card-v2.component';
// import { BannerContentMiddleComponent } from './master_modules/masters/apps/elements/banner-content-middle/banner-content-middle.component';
// import { NollieOurTeamComponent } from './master_modules/masters/apps/screening_components/our-team/nollie-our-team/nollie-our-team.component';
// import { NollieFooterComponent } from './master_modules/masters/apps/screening_components/footers/nollie-footer/nollie-footer.component';
// import { TextCardComponent } from './master_modules/masters/apps/elements/text-card/text-card.component';
// import { V2OurValuesComponent } from './master_modules/masters/apps/screening_components/our-values/v2-our-values/v2-our-values.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninSignupComponent,
    RestPasswordComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    PreviewComponent,
    LazyImgDirective,
    V3TemplatesComponent,
    V3PageSelectionPopupComponent,
    // LottieLoaderComponent,
    // LottiePoppperComponent
   
    // SimpleTestimonialCardV2Component,
    // BannerContentMiddleComponent,
    // NollieOurTeamComponent,
    // NollieFooterComponent,
    // TextCardComponent,
    // V2OurValuesComponent,



    // NollieHerobannerComponent
    // MainLandingBusinessComponent,
    // SidnavMasterComponent,
    // SecondSideNavMasterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ConstantMaterialModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
