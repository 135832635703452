import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Media, MediaSource } from './templates/template-constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TemplateMasterService {
  private accessKey: string = 'r0op0wwV9BVOxHnc-USH9vLMQhFAOVbEyIfPPVNu6hE';
  private apiUrl = 'https://api.unsplash.com';

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) { }

  private _$dataChangedInText: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  get dataChangedText() {
    return this._$dataChangedInText.asObservable();
  }
  setDataChangedText() {
    this._$dataChangedInText.next();
  }

  debounce(fn, duration: number = 500) {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    return function () {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => fn(), duration);
    };
  }

  openSnackbar(message: string) {
    this.snackbar.open(message, 'Ok', {
      duration: 1500,
    });
  }

  openSnack(message: any, action: any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }
  openDialogWithData(
    componentName: any,
    height: any,
    width: any,
    dataModel: any
  ) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '98vh',
    });
  }

  openDialog(componentName: any, height: any, width: any, dataModel: any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth: '100vw',
    });
  }

  openDeleteDialog(
    componentName: any,
    height: any,
    width: any,
    dataModel: any,
    maxwidth: any
  ) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth: maxwidth,
    });
  }

  // for listing in template category
  getCategoryList(size: number, page: number) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/category?size=${size}&page=${page}`
    );
  }

  // for search category in template
  getSerarchCategory(page: number, size: number, searchText: any) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/category?size=${size}&page=${page}&text=${searchText}`
    );
  }

  // for add category in template
  addCategory(data: any) {
    return this.http.post(
      environment.baseUrl + `admin/master/template/category`,
      data
    );
  }

  // for Edit/Update category in template
  updateCategory(data: any) {
    return this.http.put(
      environment.baseUrl + `admin/master/template/category`,
      data
    );
  }

  deleteCategory(id: any) {
    return this.http.delete(
      environment.baseUrl + `admin/master/template/category/${id}`
    )
  }

  // for listing in template content
  getContentList(size: number, page: number) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/content?size=${size}&page=${page}`
    );
  }

  getContentListV2(subIndustryId: string, categoryId: string, pageNo: number, size: number) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/component?categoryId=${categoryId}&subIndustryId=${subIndustryId}&size=${size}&page=${pageNo}`
    );
  }

  getContentList3(subIndustryId: string, categoryId: string) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/content/component?categoryId=${categoryId}&subIndustryId=${subIndustryId}`
    );
  }

  // for search content in template
  getSearchContent(page: number, size: number, searchText: any) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/content?size=${size}&page=${page}&text=${searchText}`
    );
  }
  //  for add content in template
  addContent(data: any) {
    return this.http.post(
      environment.baseUrl + `admin/master/template/content`,
      data
    );
  }

  // for listing in template component
  getComponentList(size: number, page: number) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/component?size=${size}&page=${page}`
    );
  }

  // for search component in template
  getSearchComponent(searchText: any) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/component/master?text=${searchText}`
    );
  }

  // for add component in template
  addTemplateComponent(data: any) {
    return this.http.post(
      environment.baseUrl + `admin/master/template/v2/component/master`,
      data
    );
  }

  // get all components by category
  getAllComponentsByCategory() {
    return this.http.get(
      environment.baseUrl + `admin/master/template/v2/component/master`
    );
  }

  getAllComponentsByCategoryId(categoryId: any) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/component/master?categoryId=${categoryId}`
    );
  }

  getComponentsById(id: any) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/get/master/component?id=${id}`
    );
  }

  getComponentsByCategoryId(categoryId: any) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/component/master/${categoryId}`
    );
  }

  getComponentsCount(status?: string) {
    if (status) {
      return this.http.get(
        environment.baseUrl +
        `admin/master/template/v2/get/master/component/count?status=${status}`
      );
    } else {
      return this.http.get(
        environment.baseUrl +
        `admin/master/template/v2/get/master/component/count`
      );
    }
  }

  getComponentsByCategoryIdAndStatus(categoryId: any, status: string) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/component/master/${categoryId}?status=${status}`
    );
  }
  //delete comopnent
  deleteComponent(componentId: any) {
    return this.http.delete(
      environment.baseUrl +
      `admin/master/template/v2/component/master?id=${componentId}`
    );
  }

  getSubIndustryByIndustry(industryId: any) {
    return this.http.get(
      environment.baseUrl + `admin/master/business/industry/sub/${industryId}`
    );
  }
  getComponentDetails(componentId: any) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/component/master?id=${componentId}`
    );
  }

  mergedIndustrySubIndustry() {
    return this.http.get(
      environment.baseUrl +
      `admin/master/business/industry/sub?size=1000&page=0`
    );
  }

  createPage(data) {
    return this.http.post(
      environment.baseUrl + `admin/master/template/v2/page`,
      data
    );
  }

  getAllLocalElements() {
    return this.http.get(
      environment.baseUrl + `admin/master/template/v2/component/images`
    );
  }

  createComponentForPage(data, componentId, index) {
    return this.http.post(
      environment.baseUrl +
      `admin/master/template/v2/page/component?componentId=${componentId}&index=${index}`,
      data
    );
  }

  createComponentForTemplate(pageId, componentId, index, templateId) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/${templateId}/component/add?componentId=${componentId}&index=${index}&pageId=${pageId}`
    );
  }

  savePageDataWithComponents(data) {
    return this.http.put(
      environment.baseUrl + `admin/master/template/v2/page`,
      data
    );
  }

  getListOfAllPages() {
    return this.http.get(environment.baseUrl + `admin/master/template/v2/page`);
  }

  getListAllPagesWithSubIndustry(subIndustryId) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/page?subIndustryId=${subIndustryId}`
    );
  }

  // pageNameCategories() {
  getListAllPagesWithSubIndustrySearchText(subIndustryId: any, text: any) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/page?subIndustryId=${subIndustryId}&text=${text}`
    );
  }

  pageNameCategories() {
    return this.http.get(
      environment.baseUrl + `admin/master/template/v2/page/category`
    );
  }

  duplicatePage(pageId, subIndustryId, subIndustryName) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/page/duplicate?pageId=${pageId}&subIndustryId=${subIndustryId}&subIndustryName=${subIndustryName}`
    );
  }

  deletePage(pageId) {
    return this.http.delete(
      environment.baseUrl + `admin/master/template/v2/page?pageId=${pageId}`
    );
  }

  searchPages(searchWord) {
    return this.http.get(
      environment.baseUrl + `admin/master/template/v2/page?text=${searchWord}`
    );
  }

  //templates
  createTemplate(data) {
    return this.http.post(
      environment.baseUrl + `admin/master/template/v2/`,
      data
    );
  }
  getListOfTemplates() {
    return this.http.get(environment.baseUrl + `admin/master/template/v2/`);
  }
  getSingleTemplateDetails(id) {
    return this.http.get(
      environment.baseUrl + `admin/master/template/v2/${id}`
    );
  }

  v3GetSingleTemplateDetails(id){
    return this.http.get(
      environment.serverIp+`v3/template?id=${id}`
    )
  }

  seeAllTemplates(id) {
    return this.http.get(
      environment.baseUrl + `admin/master/template/v2/?subIndustryId=${id}`
    );
  }

  deleteTemplate(id) {
    return this.http.delete(
      environment.baseUrl + `admin/master/template/v2/?id=${id}`
    );
  }

  searchTemplate(searchText) {
    return this.http.get(
      environment.baseUrl + `admin/master/template/v2/?text=${searchText}`
    );
  }

  updateTemplateComponentsData(data, tempId) {
    return this.http.put(
      environment.baseUrl + `admin/master/template/v2/${tempId}`,
      data
    );
  }

  v3updateTemplateComponent(data){
    return this.http.put(
      environment.serverIp+`v3/template`,data
    )
  }

  addMorePagesToExistingTemplate(data, templateId) {
    return this.http.put(
      environment.baseUrl + `admin/master/template/v2/${templateId}/page`,
      data
    );
  }
  searchTemplateWithSubindustryandName(subIndustryId, searchText) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/?subIndustryId=${subIndustryId}&text=${searchText}`
    );
  }

  //get page details by page id
  getPageById(pageId) {
    return this.http.get(
      environment.baseUrl + `admin/master/template/v2/page/${pageId}`
    );
  }

  //restyle template
  reStylePages(data) {
    return this.http.post(
      environment.baseUrl + `admin/master/template/v2/style`,
      data
    );
  }

  regestirationTemplates(data: any) {
    return this.http.post(
      environment.baseUrl + `admin/master/template/v2/component`,
      data
    );
  }

  getComponentsListByFilter(
    pageNo: number = 0,
    size: number = 1000,
    categoryId: string
  ) {
    return this.http.get(
      environment.baseUrl +
      `admin/master/template/v2/component?size=${size}&page=${pageNo}&categoryId=${categoryId}`
    );
  }

  createContentFromSelections(sendingPayload: any) {
    return this.http.post(
      environment.baseUrl + `admin/master/template/v2/content`,
      sendingPayload
    );
  }

  // Subindustry
  getSubIndustries(page: number, size: number) {
    return this.http.get(
      environment.baseUrl + `admin/master/business/industry/sub/count?pageNo=${page}&size=${size}`
    )
  }

  // Unsplash
  searchPhotosInUnsplash(
    searchedText: string,
    page: number = 1,
    size: number = 10
  ) {
    const header = new HttpHeaders({
      Authorization: `Client-ID ${environment.unsplash.UNSPLASH_API_TOKEN}`,
    });

    return this.http.get(
      `https://api.unsplash.com/search/photos?page=${page}&per_page=${size}&query=${searchedText}`,
      {
        headers: header,
      }
    );
  }

  // Shutterstock
  searchPhotosInShutterstock(
    searchedText: string,
    page: number = 1,
    size: number = 10
  ) {
    const header = new HttpHeaders({
      Authorization: `Bearer ${environment.shutterstock.SHUTTERSTOCK_API_TOKEN}`,
    });

    return this.http.get(
      `https://api.shutterstock.com/v2/images/search?page=${page}&per_page=${size}&query=${searchedText}`,
      {
        headers: header,
      }
    );
  }

  // Media library
  saveImagesToBusinessLibrary(mediaRequest: { data: Media[] }) {
    return this.http.post(
      environment.baseUrl + `admin/library/image/library/save`,
      mediaRequest
    );
  }

  getSavedImagesInLibrary(
    businessId: string,
    platform: MediaSource | string,
    pageNo: number,
    size: number
  ) {
    return this.http.get(
      environment.baseUrl +
      `admin/library/image/library/list/paged?pageNo=${pageNo}&size=${size}&businessId=${businessId}&platform=${platform}`
    );
  }

  // open ai API'S

  regenerateIndividual(data: any) {
    return this.http.post(
      environment.regenerateUrl+`content/suggest/website/individual`, data
    )
  }

  regenerateContent(data: any) {
    return this.http.post(
      environment.regenerateUrl+`content/suggest/website`, data
    )
  }

  // unsplash
  regenerateContentPhotos(query: string) {
    const headers = new HttpHeaders(
      {
        'Authorization': 'Client-ID ${this.accessKey}'
      }
    );
    const url = `${this.apiUrl}/search/photos?query=${query}`;
    return this.http.get(url, { headers })
  }

  getComponentListV3() {
    return this.http.get(
      environment.baseUrl+`admin/v3/get/all/components`
    )
  }

  getSectionBySectionId(sectionId: any){
    return this.http.get(
      environment.baseUrl+`admin/v3/component?id=${sectionId}`
    )
  }

  getSectionForIndustry(sectionId:any , subIndustryId: any){
    return this.http.get(
      environment.serverIp + `v3/add/section?subIndustryId=${subIndustryId}&componentId=${sectionId}`
    )
  }

  getSectionContent(sectionId:any , subIndustryId: any){
    return this.http.get(
      environment.baseUrl + `admin/v3/?subIndustryId=${subIndustryId}&categoryId=${sectionId}`
    )
  }

    v3SaveContent(data: any) {
    return this.http.post(
      environment.baseUrl + `admin/v3/content`, data
    );
  }

  v3SavePage(data: any) {
    return this.http.post(
      environment.baseUrl + `admin/v3/page`, data
    );
  }

  v3GetListAllPages(){
    return this.http.get(
      environment.baseUrl + `admin/v3/page`
    );
  }

  v3GetListAllPagesWithSubIndustry(subId) {
    return this.http.get(
      environment.baseUrl + `admin/v3/page?subId=${subId}`
    );
  }

  v3CreateTemplate(data) {
    return this.http.post(
      environment.serverIp + `v3/template`,
      data
    );
  }
  v3ListTemplates()
  {
    return this.http.get(
      environment.baseUrl + `admin/v3/template`
    );
  }
  v3seeAllTemplates(id) {
    return this.http.get(
      environment.baseUrl + `admin/v3/template/?subIndustryId=${id}`
    );
  }
  v3getComponent(subId: any , componentId: any)
  {
    return this.http.get(
      environment.baseUrl + `admin/v3/component/content?subId=${subId}&componentId=${componentId}`
    )
  }
  v3deleteContent(contentId: any)
  {
    return this.http.delete(
      environment.baseUrl + `admin/v3/content?contentId=${contentId}`
    )
  }
  v3getAllTemplates(subId)
  {
    return this.http.get(
      environment.baseUrl + `admin/v3/template?subIndustryId=${subId}`
    )
  }
  v3getSingleTemplateDetails(id) {
    return this.http.get(
      environment.baseUrl + `admin/v3/template?id=${id}`
    );
  }
}
