<section class="main_section">
    <!-- <div class="top_navbar">
        <app-home-nav-bar></app-home-nav-bar>
    </div> -->
    <div class="body_section">
        <!-- <div class="our_icon">
            <img src="./../../../../assets/images/logo.svg" alt="">
        </div> -->
        <div class="top_layer">
            <div class="section_title">
                Change Password
            </div>
            <div class="close_icon" (click)="cancel()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <div class="hr-line"></div>
        <div class="field business-field-section">
            <div class="label">
                Current Password
            </div>
            <form [formGroup]="currentPwd">
                <mat-form-field class="sfull-width" appearance="outline">
                    <input placeholder="Enter current password" matInput [(ngModel)]="currentPassword"
                    [type]="currentPasswordHide ? 'password' : 'text'"
                    formControlName="password"/>
                    <mat-icon matSuffix (click)="currentPasswordHide = !currentPasswordHide" style="cursor:pointer" class="eye-icon">{{currentPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </form>
        </div>
        <div class="field business-field-section">
            <div class="label">
                New Password
            </div>
            <form [formGroup]="pwd">
                <mat-form-field class="sfull-width" appearance="outline">
                    <input placeholder="Enter New password" matInput [(ngModel)]="password"
                    [type]="passwordHide ? 'password' : 'text'"
                    formControlName="password"/>
                    <mat-icon matSuffix (click)="passwordHide = !passwordHide" style="cursor:pointer" class="eye-icon">{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </form>
        </div>
        <div class="field business-field-section">
            <div class="label">Confirm Password</div>
            <form [formGroup]="cnfirmPwd">
                <mat-form-field class="sfull-width" appearance="outline">
                    <input placeholder="Confirm password" matInput [(ngModel)]="confirmPassword"
                    [type]="cnfirmPasswordHide ? 'password' : 'text'"
                    formControlName="confirmPassword"
                    />
                    <mat-icon matSuffix (click)="cnfirmPasswordHide = !cnfirmPasswordHide" style="cursor:pointer" class="eye-icon">{{cnfirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </form>
        </div>
        <div class="password_validation" *ngIf="confirmPasswordBoolean">
            * Passsword and Confirm Password didn't match.
        </div>
        <div class="btn_section">
            <div class="cancel_btn">
                <button (click)="cancel()">Cancel</button>
            </div>
            <div class="submit_btn">
                <button (click)="submit()">
                    <span *ngIf="loader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                    <span *ngIf="!loader">Update</span>
                </button>
            </div>
        </div>
    </div>
</section>