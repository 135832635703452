import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplateMasterService } from '../master_modules/masters/apps/template/template-master.service';
import { buttonShape, buttonStyle } from '../constants/common-constant';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DynamicpickcomponentsService } from '../services/dynamicpickcomponents.service';
import { EventEmmiterService } from '../services/event-emmiter.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  @ViewChild('displayWebsite', { read: ViewContainerRef })
  displayWebsite: ViewContainerRef | any;

  constructor(
    private _route: ActivatedRoute,
    private templateService: TemplateMasterService,
    private snackBar: MatSnackBar,
    private pickupService: DynamicpickcomponentsService,
    private eventHelper: EventEmmiterService
  ) { }

  ngOnInit(): void {
    this.gettingPathParams()
    this.buttonCickedChecks();
  }

  currentPageIndex = 0;
  templatePages: any[] = [];
  totalTemplateData: any[] = [];
  positionRestyleTop: any = '';
  positionRestyleRight: any = '';
  restyleComponent: boolean = false;
  bStyle: any;
  buttonStyles = buttonStyle;
  bShape: any;
  buttonShape = buttonShape;
  btnColor: any;
  btnTextColor: any;
  getWebsiteLoader: boolean = false;
  path: any;
  selectedPageData: any;

  buttonCickedChecks() {
    this.eventHelper.buttonCick.subscribe(
      (res: any) => {
        console.log(res.buttonData);
        if (res.buttonData.redirectionType === 'SAME_PAGE') {
          this.pickupService.samePageRedirection(this.selectedPageData, res.buttonData.redirectTo);
        }
        else if (res.buttonData.redirectionType === 'INTERNAL') {
          this.displayWebsite.clear()
          this.templatePages.forEach(webData => {
            if (res.buttonData.redirectTo === webData.path) {
              if (webData.path != '' && webData.components[0].category.name != 'Top Nav Bar') {
                webData.components.splice(0, 0, this.templatePages[0].components[0])
                // console.log(webData.components.length)
                webData.components.splice(webData.components.length, 0, this.templatePages[0].components[this.templatePages[0].components.length - 1]);
              }
              else { }
              this.pickupService.dyanmicComponentPickupPreview(this.displayWebsite, webData.components, false, false);
              this.path = webData.path;
              this.selectedPageData = webData;
            }
          });
        }
        else {
          this.pickupService.externelUrls(res.buttonData.redirectTo)
        }
      }
    )
  }

  showingPreviewText : any = ''
  gettingPathParams() {
    this._route.params.subscribe(
      (param: any) => {
        let currentUrl = window.location.href.split('/')
        if (currentUrl.includes('template')) {
          this.getWebsite(param.id)
          this.showingPreviewText = 'Template Preview';
        }
        else {
          this.getPage(param.id);
          this.showingPreviewText = 'Page Preview'
        }
      }
    )
  }

  getWebsite(id) {
    this.templateService.getSingleTemplateDetails(id).subscribe(
      (res: any) => {
        this.currentPageIndex = 0;
        console.log(res.data)
        this.totalTemplateData = res.data.template;
        this.templatePages = res.data.pages;
        // this.templatePages.forEach((pages:any) => {
        //   if(pages.seoData === null){
        //     pages.seoData = JSON.parse(JSON.stringify(this.seoData))
        //   }
        // });
        this.buttonStyles.forEach((btnStyles) => {
          if (btnStyles.value === res.data.pages[0].btnStyle) {
            this.bStyle = btnStyles.value
          }
        });

        //attaching current button shapes
        this.buttonShape.forEach((btnShapes) => {
          if (btnShapes.value === res.data.pages[0].btnShape) {
            this.bShape = btnShapes.value;
          }
        });

        //attaching button color and button text color
        this.btnColor = res.data.pages[0].btnColor;
        this.btnTextColor = res.data.pages[0].btnTextColor;
        // this.totalWebsiteData = res.data;
        // this.eventHelper.pageLevelEdit.emit({status:true})
        this.pickupService.dyanmicComponentPickupPreview(this.displayWebsite, res.data.pages[0].components, false, false);
        // this.eventHelper.sendingPageData.emit({data:this.templatePages[0]})
        this.getWebsiteLoader = false;
      },
      (err) => {
        // this.pageLoader = false;
        this.getWebsiteLoader = false;
        this.snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
  }

  getPage(id) {
    this.templateService.getPageById(id).subscribe(
      (res: any) => {
        this.pickupService.dyanmicComponentPickup(this.displayWebsite, res.data.components, false, false);
      },
      (err: any) => {
        this.snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
  }
}
