import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateMasterService } from '../../../template-master.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DeleteTemplateComponent } from '../delete-template/delete-template.component';

@Component({
  selector: 'app-preview-rightside-template',
  templateUrl: './preview-rightside-template.component.html',
  styleUrls: ['./preview-rightside-template.component.scss']
})
export class PreviewRightsideTemplateComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<PreviewRightsideTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private templateMasterService: TemplateMasterService,
    private snackbar: MatSnackBar,
    private router : Router,
  ) {

  }
  ngOnInit(){
    this.dialogRef.updatePosition({top:'0px',right:'0px'});

    this.v3getTemplateDetails(this.data.pageData.id)
  }

  templateData: any;
  getLoader : boolean = false;
  v3getTemplateDetails(id){
    this.getLoader = true;
    this.templateMasterService.v3GetSingleTemplateDetails(id).subscribe(
      (res:any) => {
        console.log(res.data)
        this.templateData = res.data;
        // console.log(this.templateData)
        this.getLoader = false;
      },
      (err) => {
        this.getLoader = false;
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }


  deleteComponent(element:any) {
    let dialog = this.templateMasterService.openDeleteDialog(
      DeleteTemplateComponent,
      '250px',
      '350px',
      { data: element },
      '80vw'
    );
    dialog.afterClosed().subscribe((data: any) => {
      if(data){
        this.dialogRef.close({data:'success'});
        this.router.navigate(['/master/template/v3-template'])
      }
    });
  }
  editComponent(element:any){
    // console.log(element)
    this.dialogRef.close();
    this.router.navigate([`/master/template/view-template/${element}`])
  }

  cancel() {
    this.dialogRef.close();
  }

  openCalls: any
  pageLoader: boolean = false
}
